import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../../components/layout';
import ProductHero from '../../components/product-hero';
import ProductTable from '../../components/product-table';

const ProductOne = () => {
  // wooden - broom - handle - threaded - induba.jpg;
  const { broomHandle } = useStaticQuery(graphql`
    query {
      broomHandle: file(relativePath: { eq: "cabos-de-escoba-induba.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <div className="max-w-6xl mx-auto my-10 px-4 lg:px-0">
        <ProductHero
          title="Cabo de Escoba"
          description="Cabo de Escoba de Madera de pino taeda."
          uses="Cabo de escoba de madera para uso domestico. Se adopto el cabo para utilizar con escobillones y escurridores."
          isEnglish={false}
        >
          <Img
            className="portrait"
            fluid={broomHandle.childImageSharp.fluid}
            fadeIn
          />
        </ProductHero>

        <ProductTable isEnglish={false}>
          <tbody>
            <tr>
              <td className="p-3 align-top" rowSpan="6">
                Cabos de Pino
              </td>
              <td className="p-3">1.10 mts</td>
              <td className="p-3">23.5 mm, 25 mm, 28 mm</td>
            </tr>
            <tr>
              <td className="p-3">1.20 mts</td>
              <td className="p-3">23.5 mm, 25 mm, 28 mm</td>
            </tr>
            <tr>
              <td className="p-3">1.30 mts</td>
              <td className="p-3">23.5 mm, 25 mm, 28 mm</td>
            </tr>
            <tr>
              <td className="p-3">1.40 mts</td>
              <td className="p-3">23.5 mm, 25 mm, 28 mm</td>
            </tr>
            <tr>
              <td className="p-3">1.50 mts</td>
              <td className="p-3">23.5 mm, 25 mm, 28 mm</td>
            </tr>
            <tr>
              <td className="p-3">2.0 mts</td>
              <td className="p-3">23.5 mm, 25 mm, 28 mm</td>
            </tr>
          </tbody>
        </ProductTable>
      </div>
    </Layout>
  );
};

export default ProductOne;
